<template>
  <el-container>
    <el-header class="el-header">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            sql语句：
            <el-input v-model="sqlStatement"></el-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            申请人：
            <el-input v-model="proposer"></el-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            <span class="demonstration">申请时间</span>
            <el-date-picker
                v-model="applyTimeSection"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 100%">
            </el-date-picker>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            <span class="demonstration">生产环境执行日期</span>
            <el-date-picker
                v-model="proExecuteTimeSection"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 100%">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row class="el-header2">
        <el-col :span="2">
          <div style="margin-top: 15px">
            <el-button type="success" @click="openMetaDataEnvs" >新增</el-button>
            <AddMetaDataEnvs ref="addMetaDataEnvs" />
          </div>
        </el-col>

        <el-col :span="2">
          <div style="margin-top: 15px">
            <el-button type="primary" @click="queryInfo(1, 5)">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="el-main">
      <div class="app-container">
        <div>
          <el-table
              :data="list"
              style="width: 100%;margin-bottom: 20px;"
              :span-method="arraySpanMethod"
              row-key="id"
              border
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table
                    class="table-in-table"
                    :show-header="false"
                    :data="props.row.dbs"
                    style="width: 100%;"
                    row-key="id"
                    :span-method="arraySpanMethod"
                    border
                >
                  <el-table-column type="expand">
                    <el-table-column prop="dbCode" label="数据库名称" width="180"></el-table-column>
                    <el-table-column prop="dbName" label="数据库中文名称" width="180"></el-table-column>
                    <el-table-column label="操作" width="120">
                      <!-- slot-scope="props" -->
                      <template>
                        <el-button type="text" size="small">移除</el-button>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="envEname" label="用户名" width="180"></el-table-column>
            <el-table-column prop="envName" label="环境名称" width="180"></el-table-column>
            <el-table-column label="操作" width="120">
              <!-- slot-scope="props" -->
              <template>
                <el-button type="text" size="small">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>

    <el-footer class="el-footer">
      <div class="pageClass">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currPageParam"
            :page-sizes="[5, 10, 15, 20, 100]"
            :page-size="pageSizeParam"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "UserList",
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },

  methods: {
    // otherParams: column, rowIndex,
    arraySpanMethod({ row, columnIndex }) {
      if (!row.dbs) {
        if (columnIndex === 0) {
          return [0, 0];
        } else if (columnIndex === 1) {
          return [1, 2];
        }
      }
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    openMetaDataEnvs() {
      this.$nextTick(() => {
        this.$refs["addMetaDataEnvs"].cOpenMetaDataEnvs();
      });
    },
  },

  data() {
    return {
      list: [
        {
          id: "01",
          envEname: "01env",
          envName: "01环境",
          dbs: [
            {
              id: "0101",
              dbEName: "01envDb1",
              dbName: "01环境数据库1"
            },
            {
              id: "0102",
              dbEName: "01envDb2",
              dbName: "01环境数据库2"
            }
          ]
        },
        {
          id: "02",
          envEname: "02env",
          envName: "02环境",
          dbs: [
            {
              id: "0201",
              dbEName: "02envDb1",
              dbName: "02环境数据库1"
            },
            {
              id: "0202",
              dbEName: "02envDb2",
              dbName: "02环境数据库2"
            },
            {
              id: "0203",
              dbEName: "02envDb3",
              dbName: "02环境数据库3"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.el-header {
  background-color: #e7e7e7;
  height: 100px;
  margin-top: 3.5%;
}
.el-header2 {
  background-color: #e7e7e7;
  width: 100%;
}
.el-main {
  padding: 0;
  margin-top: 3.5%;
  height: calc(100vh - 70px);
}
.el-footer {
  position: sticky;

  margin-top: 15px;
  top: 95%;
}
</style>