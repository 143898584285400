<template>
  <div id="nav">
    <!-- @select="handleSelect" -->
    <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"

        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">

      <!-- 新增记录页面导航（弃用） -->
<!--      <el-col :span="2">-->
<!--        <router-link to="/typeInDdl">-->
<!--          <el-menu-item index="1">新增记录</el-menu-item>-->
<!--        </router-link>-->
<!--      </el-col>-->

      <el-col :span="2">
        <router-link to="/infoList">
          <el-menu-item index="2">记录信息</el-menu-item>
        </router-link>
      </el-col>

      <el-col :span="3">
        <el-submenu index="3">
          <template slot="title">元数据维护</template>
          <router-link to="/userList">
            <el-menu-item index="3-1">用户管理</el-menu-item>
          </router-link>
          <router-link to="/envDbList">
            <el-menu-item index="3-2">环境/数据库管理</el-menu-item>
          </router-link>
        </el-submenu>
      </el-col>
      <el-col :span="3">
        <el-button type="text" style="margin-top: 7px; margin-left: 520%" @click="logout">退出登录</el-button>
      </el-col>

      <!--    <el-submenu index="2">-->
      <!--      <template slot="title">我的工作台</template>-->
      <!--      <el-menu-item index="2-1">选项1</el-menu-item>-->
      <!--      <el-menu-item index="2-2">选项2</el-menu-item>-->
      <!--      <el-menu-item index="2-3">选项3</el-menu-item>-->
      <!--      <el-submenu index="2-4">-->
      <!--        <template slot="title">选项4</template>-->
      <!--        <el-menu-item index="2-4-1">选项1</el-menu-item>-->
      <!--        <el-menu-item index="2-4-2">选项2</el-menu-item>-->
      <!--        <el-menu-item index="2-4-3">选项3</el-menu-item>-->
      <!--      </el-submenu>-->
      <!--    </el-submenu>-->
    </el-menu>
  </div>
</template>

<script>
    export default {
      name: "MenuOptionTop",

      data() {
        return {
          activeIndex: '1',
          activeIndex2: '2'
        };
      },
      methods: {
        // handleSelect(key, keyPath) {
        //   console.log(key, keyPath);
        // }
        logout() {
          this.$axios.post('/logout').then(res => {
            if (res.code == 200) {
              localStorage.clear();
              sessionStorage.clear();
              this.$router.push({ name:"loginPage" });
              this.$message.success(res.data);
              window.location.reload();
            }
          }).catch(err => {
            console.log(err);
          });
        }
      }
    }
</script>

<style scoped>
  #nav {
    z-index: 999;
    height: 65px;
    width: 100%;
    position: fixed;
  }
</style>