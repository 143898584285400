import VueRouter from "vue-router";

import UserLogin from "../components/userOA/UserLogin";

import UserList from "@/components/metaData/UserList";
import EnvDbList from "@/components/metaData/EnvDbList";

import InfoList from "../components/main/InfoList";

export default new VueRouter({
    routes: [
        {
            path: "/",
            name: "loginPage",
            component: UserLogin,
            meta: {
                notShowNavMenu: true
            }
        },
        {
            path: "/userList",
            name: "userList",
            component: UserList,
            meta: {
                notShowNavMenu: false
            }
        },
        {
            path: "/envDbList",
            name: "envDbList",
            component: EnvDbList,
            meta: {
                notShowNavMenu: false
            }
        },
        {
            path: "/infoList",
            name: "infoList",
            component: InfoList,
            meta: {
                notShowNavMenu: false
            }
        }
    ]
});

// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function (location, onComplete, onAbort) {
//     return routerPush.call(this, location, onComplete, onAbort).catch(err => {
//         if (!/^NavigationDuplicated/.test(err)) {
//             console.log(err);
//         }
//     })
// }