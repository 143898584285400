<template>
  <div>
<!--    <el-radio-group v-model="direction">-->
<!--      <el-radio label="ltr">从左往右开</el-radio>-->
<!--      <el-radio label="rtl">从右往左开</el-radio>-->
<!--      <el-radio label="ttb">从上往下开</el-radio>-->
<!--      <el-radio label="btt">从下往上开</el-radio>-->
<!--    </el-radio-group>-->

<!--    <el-col :span="2">-->
<!--      <div style="margin-top: 15px">-->
<!--        <el-button type="success" @click="drawer = true">新增</el-button>-->
<!--      </div>-->
<!--    </el-col>-->

    <el-drawer
        title="新增记录"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
      <el-form ref="form" :model="ddlExecuteVo" label-width="100px" size="mini" inline :rules="rules" inline-message>
<!--        <el-form-item label="sql语句" required="required">-->
<!--          <el-input type="textarea" style="width: 350px;" :rows="10"-->
<!--                    show-word-limit maxlength="50000"-->
<!--                    v-model="ddlExecuteVo.ddlExecuteRecord.sqlStatement"></el-input>-->
<!--        </el-form-item>-->

        <el-form-item label="sql语句">
          <!-- @allUrls="allUrls" -->
          <editors ref="myTextEditr" :formUpdata="ddlExecuteVo.ddlExecuteRecord.sqlStatement"
                   :disabled="editors_disabled" @update:value="(v) => (ddlExecuteVo.ddlExecuteRecord.sqlStatement = v)">
          </editors>
        </el-form-item>

        <el-divider></el-divider>

        <el-form-item label="申请人" required="required">
          <el-input v-model="ddlExecuteVo.ddlExecuteRecord.proposer"></el-input>
        </el-form-item>

        <el-form-item label="申请时间" required="required">
          <el-date-picker
              v-model="ddlExecuteVo.ddlExecuteRecord.applyTime"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="执行人" required="required">
          <el-input v-model="ddlExecuteVo.ddlExecuteRecord.executor"></el-input>
        </el-form-item>

        <el-form-item label="执行情况" required="required">
          <div class="block">
            <!-- :options="ddlExecuteVo.ddlExecuteDbs.options" -->
            <el-cascader
                v-model="ddlExecuteVo.ddlExecuteDbs"
                :options="cascader_options"
                :props="cascader_props"
                @change="handleChange"
            ></el-cascader>
          </div>
        </el-form-item>

        <el-form-item label="生产环境执行时间">
          <el-date-picker type="date" placeholder="选择日期" v-model="ddlExecuteVo.ddlExecuteRecord.proExecuteTime"></el-date-picker>
        </el-form-item>

        <el-form-item label="是否完结">
              <el-switch
                  style="display: block; margin-top: 3px"
                  v-model="ddlExecuteVo.ddlExecuteRecord.isOver"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="是"
                  active-value="1"
                  inactive-text="否"
                  inactive-value="0">
              </el-switch>
        </el-form-item>

        <el-row>
          <el-col :span="24">
            <div style="margin-top: 15px">
              <el-form-item>
                <el-button type="primary" @click="submitSave">保存记录</el-button>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <!-- <el-form-item label="执行环境/数据库">
        <el-checkbox-group v-model="ddlExecuteRecord.ddlSysDbCodes">
          <el-checkbox name="type" label="1">开发环境/pzdb</el-checkbox>
          <el-checkbox name="type" label="2">开发环境/pzdb_c</el-checkbox>
          <el-checkbox name="type" label="3">开发环境/schb</el-checkbox> <br>
          <el-checkbox name="type" label="4">测试（预生产）环境/zjdbdev</el-checkbox>
          <el-checkbox name="type" label="5">测试（预生产）环境/zjdbpro</el-checkbox>
          <el-checkbox name="type" label="6">测试（预生产）环境/schb</el-checkbox><br>
          <el-checkbox name="type" label="7">生产环境/pzdb</el-checkbox>
          <el-checkbox name="type" label="8">生产环境/schb</el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
      <!--      <div class="block">-->
      <!--        <span class="demonstration">执行情况</span>-->
      <!--        <el-cascader-->
      <!--            v-model="envMetaData.value"-->
      <!--            :options="envMetaData.options"-->
      <!--            @change="handleChange"></el-cascader>-->
      <!--      </div>-->

      <!--      <el-form-item label="即时配送">-->
      <!--        <el-switch v-model="form.delivery"></el-switch>-->
      <!--      </el-form-item>-->

      <!--      <el-form-item label="特殊资源">-->
      <!--        <el-radio-group v-model="form.resource">-->
      <!--          <el-radio label="线上品牌商赞助"></el-radio>-->
      <!--          <el-radio label="线下场地免费"></el-radio>-->
      <!--        </el-radio-group>-->
      <!--      </el-form-item>-->
    </el-drawer>
  </div>
</template>

<script>

import editors from "@/components/main/editors";
export default {
  name: "TypeInDdl",
  components: {
    editors
  },

  data() {
    return {
      id: "",
      ddlExecuteVo: {
        ddlExecuteRecord: {
          id: "",
          sqlStatement: "", // sql语句
          proposer: "", // 申请人
          applyTime: "", // 申请时间
          executor: "", // 执行人
          proExecuteTime: "", // 生产环境执行时间
          isOver: "" // 是否完结 1-是
        },
        ddlExecuteDbs: [
          {
            id: "",
            envEname: "", // 环境英文名称
            envCname: "", // 环境中文名称
            dbEname: "", // 数据库英文名称
            dbCname: "" // 数据库中文名称
          }
        ]
      },
      cascader_props: {
        multiple: true,
        value: "ddlSysViewMap",
        label: "serverName",
        children: "ddlSysDbServers",
      },
      cascader_options: [],

      direction: 'ltr',

      drawer: false,
      editors_disabled: false,

      // 参数校验
      rules: {
        sqlStatement: [
          { required: true, message: "输入sql语句", trigger: "blur" }
        ],
        proposer: [
          { required: true, message: "输入申请人姓名", trigger: "blur" }
        ],
        applyTime: [
          { required: true, message: "输入申请时间", trigger: "blur" }
        ],
        executor: [
          { required: true, message: "输入执行人姓名", trigger: "blur" }
        ],
        ddlExecuteDbs: [
          { required: true, message: "选择执行环境/及数据库", trigger: "blur" }
        ]
      },
    }
  },
  methods: {
    submitSave() {
      this.$axios.post('/ddlExecuteRecord/saveRecord', {
        ddlExecuteRecord: this.ddlExecuteVo.ddlExecuteRecord, // 主记录
        ddlSysDbCodes: this.ddlExecuteVo.ddlExecuteDbs, // 执行环境/数据库
      }).then(res => {
        this.$message.success(res.data.msg);
        location.reload();
      }).catch(function () {
        this.$message.error("信息录入不完整！");
      });
    },
    queryInfoById(id) {
      this.$axios.post('/ddlExecuteRecord/queryRecordInfo', {
        id: id
      }).then(res => {
        this.ddlExecuteVo.ddlExecuteRecord = res.data.ddlExecuteRecord;
        this.ddlExecuteVo.ddlExecuteDbs = res.data.ddlSysDbCodes;
        this.drawer = true;
      }).catch(function () {
        this.$message.error("查询数据时遇到问题！");
      });
    },
    // param: value
    handleChange() {},
    cOpenDdlInput() {
      this.drawer = true;
    },
    query_options() {
      let url = "/ddlSysDbServer/queryEnvList";
      let params = {};
      this.$axios
        .post(url, params)
        .then((res) => {
          if (res.status == 200) {
            this.$set(this, "cascader_options", res.data);
            // this.cascader_options = res.code;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          // param _
          .then(() => {
            done();
          })
          .catch(_ => {console.log(_);});
    }
  },
  created() {
    // 渲染前先获取数据
    this.query_options();
  },
  mounted() {},
};
</script>

<style scoped>

</style>