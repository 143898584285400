<template>
  <el-container>
    <el-header class="el-header">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            sql语句：
            <el-input v-model="sqlStatement"></el-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            申请人：
            <el-input v-model="proposer"></el-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
              <span class="demonstration">申请时间</span>
              <el-date-picker
                  v-model="applyTimeSection"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  style="width: 100%">
              </el-date-picker>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content bg-purple">
            <span class="demonstration">生产环境执行日期</span>
            <el-date-picker
                v-model="proExecuteTimeSection"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 100%">
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row class="el-header2">
        <el-col :span="2">
<!--          <div style="margin-top: 15px">-->
<!--            <el-button type="success" @click="openIn = true">新增</el-button>-->
<!--          </div>-->
          <div style="margin-top: 15px">
            <!-- 父组件点击事件 -->
            <el-button type="success" @click="openDdlInput">新增</el-button>
            <!-- 父组件使用子组件，写上ref="" -->
            <TypeInDdl ref="typeInDdl"></TypeInDdl>
          </div>
        </el-col>

        <el-col :span="2">
          <div style="margin-top: 15px">
            <el-button type="primary" @click="queryInfo(1, 5)">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="el-main">
      <el-empty v-show="list.length < 1" description="暂无数据"></el-empty>

      <el-descriptions v-show="list.length > 0" border :column="1" title="变动信息：" style="margin-top: 10px" v-for="info in list" :key="info.id">
        <el-descriptions-item label="sql语句">
          <div v-html="info.sqlStatement"></div>
        </el-descriptions-item>
        <el-descriptions-item label="申请人">{{ info.proposer }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ info.applyTime }}</el-descriptions-item>
        <el-descriptions-item label="执行人">{{ info.executor }}</el-descriptions-item>
        <el-descriptions-item label="生产环境升级时间">{{ info.proExecuteTime }}</el-descriptions-item>
        <el-descriptions-item label="已升级数据库">
          <el-tag v-for="ddlExecuteDb in info.ddlExecuteDbs" :key="ddlExecuteDb.id">
            {{ ddlExecuteDb.envEname }}-{{ddlExecuteDb.dbEname}}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否完结">{{ info.isOver !== "0" ? "是" : "否" }}</el-descriptions-item>

        <el-descriptions-item label="操作">
          <el-button type="warning" @click="updateRecord(info.id)">修改</el-button>
          <!-- zx 临时push，待优化（多选） -->
          <el-button type="danger" @click="deleteRecord((info.id))">删除</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-main>

    <el-footer>
      <div class="pageClass">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currPageParam"
            :page-sizes="[5, 10, 15, 20, 100]"
            :page-size="pageSizeParam"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount">
        </el-pagination>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
    import TypeInDdl from "@/components/main/TypeInDdl";

    export default {
      name: "InfoList",
      components: {TypeInDdl},
      data() {
        return {
          list: [
            {
              id: null, // 数据持久存储唯一标识
              applyTime: null, // 申请时间
              proposer: null, // 申请人
              executor: null, // 执行人
              sqlStatement: null, // sql语句
              ddlExecuteDbs: [
                  {
                    id: null, // 数据持久存储唯一标识
                    unionRecordId: null, // 关联记录ID
                    envEname: null,
                    envCname: null, // 环境中文名称
                    dbEname: null, // 数据库英文名称
                    dbCname: null, // 数据库中文名称
                    orderSource: null, // 排序
                    createDate: null // 创建时间
                  }
              ],
              isOver: null // 是否完结
            }
          ],
          // 分页参数
          totalCount: 0,
          currPageParam: 1,
          pageSizeParam: 5,
          showData: true,

          // 查询条件
          sqlStatement: "", // sql语句
          proposer: "", // 申请人
          applyTimeSection: null, // 申请时间区间
          proExecuteTimeSection: null, // 生产环境执行时间区间

          // 记录id集合，删除用
          ids: []
        }
      },
      methods: {
        queryInfo(currPageParam, pageSizeParam) {
          this.$axios.post('/ddlExecuteRecord/queryRecordList', {
            //params参数必写 , 如果没有参数传{}也可以
            currPage: currPageParam, // 跳过的页数
            pageSize: pageSizeParam, // 查询条数
            totalCount: 0, // 总条数

            sqlStatement: this.sqlStatement, // sql语句
            proposer: this.proposer, // 申请人
            applyTimeSection: this.applyTimeSection, // 申请时间区间
            proExecuteTimeSection: this.proExecuteTimeSection, // 生产环境执行时间区间
          }).then(res => {
            this.list = res.data.list;
            this.totalCount = res.data.totalCount;
          }).catch(err => {
            console.log(err);
          });
        },
        // 父组件methods
        openDdlInput() {
          this.$nextTick(() => {
            this.$refs["typeInDdl"].cOpenDdlInput();
          });
        },
        // zx 暂时写在一个组件中
        deleteRecord(infoId) {
            this.$confirm('确定删除该条记录？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
                if(infoId) {
                  this.ids.push(infoId);

                  this.$axios.post('/ddlExecuteRecord/deleteRecord', {
                    ids: this.ids
                  }).then(res => {
                    this.queryInfo(1, 5);
                    this.$message.success(res.data.msg);
                  }).catch(err => {
                    console.log(err);
                  });
                }
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
        },
        updateRecord(id) {
          this.$nextTick(() => {
            this.$refs["typeInDdl"].queryInfoById(id);
          });
        },
        handleSizeChange(pageSizeChange) {
          this.queryInfo(this.currPageParam, pageSizeChange);
        },
        handleCurrentChange(page) {
          this.queryInfo(page, this.pageSizeParam);
        }
      },
      created() {
        this.queryInfo(this.currPageParam, this.pageSizeParam);
      }
    }
</script>

<style scoped>
.el-header {
  background-color: #e7e7e7;
  height: 100px;
  margin-top: 3.5%;
}
.el-header2 {
  background-color: #e7e7e7;
  width: 100%;
}
.el-main {
  padding: 0;
  margin-top: 3.5%;
  height: calc(100vh - 70px);
}


  .pageClass {
    margin-top: 30px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .bg-purple {
    background: #d3dce6;
  }
</style>