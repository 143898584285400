<template>
  <div>
    <el-upload class="avatar-uploader-editor" :action="serverUrl" name="img" :show-file-list="false"
               :on-success="uploadSuccess" :on-error="uploadError" :headers="myheaders" accept=".jpg, .jpeg, .png, .JPG, .JPEG">
    </el-upload>
    <!-- <el-upload class="avatar-uploader-editor-video" :action="serverUrl" name="video" :show-file-list="false"
      :on-success="uploadSuccessVideo" :on-error="uploadError"></el-upload>
    <el-upload class="avatar-uploader-editor-voice" :action="serverUrl" name="voice" :show-file-list="false"
      :on-success="uploadSuccessVoice" :on-error="uploadError"></el-upload> -->
    <quill-editor v-model="sqlStatement" ref="myQuillEditor" :options="editorOption" class="editor"
                  @ready="onEditorReady($event)" @change="editorChange(sqlStatement)">
      <!-- <div id="toolbar" slot="toolbar">
        <button class="ql-bold" title="加粗">Bold</button>
        <button class="ql-italic" title="斜体">Italic</button>
        <button class="ql-underline" title="下划线">underline</button>
        <button class="ql-strike" title="删除线">strike</button>
        <button class="ql-blockquote" title="引用"></button>
        <button class="ql-code-block" title="代码"></button>
        <button class="ql-header" value="1" title="标题1"></button>
        <button class="ql-header" value="2" title="标题2"></button>
        <button class="ql-list" value="ordered" title="有序列表"></button>
        <button class="ql-list" value="bullet" title="无序列表"></button>
        <select class="ql-header" title="段落格式">
          <option selected>段落</option>
          <option value="1">标题1</option>
          <option value="2">标题2</option>
          <option value="3">标题3</option>
          <option value="4">标题4</option>
          <option value="5">标题5</option>
          <option value="6">标题6</option>
        </select>
        <select class="ql-size" title="字体大小">
          <option value="10px">10px</option>
          <option value="12px">12px</option>
          <option value="14px">14px</option>
          <option value="16px" selected>16px</option>
          <option value="18px">18px</option>
          <option value="20px">20px</option>
          <option value="30px">30px</option>
        </select>
        <select class="ql-font" title="字体">
          <option value="SimSun">宋体</option>
          <option value="SimHei">黑体</option>
          <option value="Microsoft-YaHei">微软雅黑</option>
          <option value="KaiTi">楷体</option>
          <option value="FangSong">仿宋</option>
          <option value="Arial">Arial</option>
        </select>
        <select class="ql-color" value="color" title="字体颜色"></select>
        <select class="ql-background" value="background" title="背景颜色"></select>
        <select class="ql-align" value="align" title="对齐"></select>
        <button class="ql-clean" title="清除字体样式"></button>
        <button class="ql-image" title="图片"></button>
        <button class="ql-video" title="视频"></button>
        <button class="ql-audio" title="音频"><i class="el-icon-headset"></i></button>
      </div> -->
    </quill-editor>
  </div>
</template>

<script>
import { Quill, quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// // 自定义字体大小
var Size = Quill.import("attributors/style/size");
Size.whitelist = ["10px", "12px", "16px", "18px", "20px", "30px"];
Quill.register(Size, true);
// 自定义字体类型
var fonts = [
  "SimSun",
  "SimHei",
  "Microsoft-YaHei",
  "KaiTi",
  "FangSong",
  "Arial",
  "sans-serif",
];
var Font = Quill.import("formats/font");
Font.whitelist = fonts;
Quill.register(Font, true);

//视频标签插入（样式保持宽度100%）
// import Video from "./video.js";
// Quill.register(Video, true);
// //音频标签插入
// import Audio from "./audio.js";
// Quill.register(Audio, true);
// 工具栏配置项
const toolbarOptions = [
  // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["bold", "italic", "underline", "strike"],
  // 引用  代码块-----['blockquote', 'code-block']
  ["blockquote", "code-block"],
  // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ header: 1 }, { header: 2 }],
  // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ list: "ordered" }, { list: "bullet" }],
  // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  // [{ script: "sub" }, { script: "super" }],
  // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ indent: "-1" }, { indent: "+1" }],
  // 文本方向-----[{'direction': 'rtl'}]
  // [{ direction: "rtl" }],
  // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ size: ["10px", "12px", false, "16px", "18px", "20px", "30px"] }],
  // 字体种类-----[{ font: [] }]
  [
    {
      font: [
        false,
        "SimSun",
        "SimHei",
        "Microsoft-YaHei",
        "KaiTi",
        "FangSong",
        "Arial",
        "sans-serif",
      ],
    },
  ],
  // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ color: [] }, { background: [] }],
  // 对齐方式-----[{ align: [] }]
  [{ align: [] }],
  // 清除文本格式-----['clean']
  ["clean"],
  // 链接、图片、视频-----['link', 'image', 'video']
  ["image"],
];
// toolbar标题
const titleConfig = [
  { Choice: ".ql-insertMetric", title: "跳转配置" },
  { Choice: ".ql-bold", title: "加粗" },
  { Choice: ".ql-italic", title: "斜体" },
  { Choice: ".ql-underline", title: "下划线" },
  { Choice: ".ql-header", title: "段落格式" },
  { Choice: ".ql-strike", title: "删除线" },
  { Choice: ".ql-blockquote", title: "块引用" },
  { Choice: ".ql-code", title: "插入代码" },
  { Choice: ".ql-code-block", title: "插入代码段" },
  { Choice: ".ql-font", title: "字体" },
  { Choice: ".ql-size", title: "字体大小" },
  { Choice: '.ql-list[value="ordered"]', title: "编号列表" },
  { Choice: '.ql-list[value="bullet"]', title: "项目列表" },
  // { Choice: ".ql-direction", title: "文本方向" },
  { Choice: '.ql-header[value="1"]', title: "标题一" },
  { Choice: '.ql-header[value="2"]', title: "标题二" },
  { Choice: ".ql-align", title: "对齐方式" },
  { Choice: ".ql-color", title: "字体颜色" },
  { Choice: ".ql-background", title: "背景颜色" },
  { Choice: ".ql-image", title: "图片" },
  // { Choice: ".ql-video", title: "视频" },
  // { Choice: ".ql-link", title: "添加链接" },
  // { Choice: ".ql-formula", title: "插入公式" },
  { Choice: ".ql-clean", title: "清除字体格式" },
  // { Choice: '.ql-script[value="sub"]', title: "下标" },
  // { Choice: '.ql-script[value="super"]', title: "上标" },
  { Choice: '.ql-indent[value="-1"]', title: "向左缩进" },
  { Choice: '.ql-indent[value="+1"]', title: "向右缩进" },
  { Choice: ".ql-header .ql-picker-label", title: "段落格式" },
  // { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: "标题一" },
  // { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: "标题二" },
  // { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: "标题三" },
  // { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: "标题四" },
  // { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: "标题五" },
  // { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: "标题六" },
  // { Choice: ".ql-header .ql-picker-item:last-child", title: "标准" },
  // { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: "小号" },
  // { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: "大号" },
  // { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: "超大号" },
  // { Choice: ".ql-size .ql-picker-item:nth-child(2)", title: "标准" },
  // { Choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },
  // {
  //   Choice: '.ql-align .ql-picker-item[data-value="center"]',
  //   title: "居中对齐",
  // },
  // {
  //   Choice: '.ql-align .ql-picker-item[data-value="right"]',
  //   title: "居右对齐",
  // },
  // {
  //   Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  //   title: "两端对齐",
  // },
];
export default {
  name: "elEditors",
  props: {
    formUpdata: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    quillEditor,
  },

  data() {
    return {
      sqlStatement: JSON.parse(JSON.stringify(this.formUpdata)),
      editorOption: {
        placeholder: "请输入", //文章初始化提示语
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            // container: "#toolbar",
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件 // 调用element的图片上传组件
                  document
                      .querySelector(".avatar-uploader-editor input")
                      .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              // video: function (value) {
              //   if (value) {
              //     // 触发input框选择视频文件
              //     document
              //       .querySelector(".avatar-uploader-editor-video input")
              //       .click();
              //   } else {
              //     this.quill.format("video", false);
              //   }
              // },
              // audio: function (value) {
              //   if (value) {
              //     // 触发input框选择音频文件
              //     document
              //       .querySelector(".avatar-uploader-editor-voice input")
              //       .click();
              //   } else {
              //     this.quill.format("audio", false);
              //   }
              // },
            },
          },
        },
      },
      serverUrl: window.ntkoHost + "/notificationAnnController/savePicture", //上传的图片服务器地址
      myheaders: {
        "Sin-Token": sessionStorage.getItem("token"),
        "Sin-RoleId": localStorage.getItem("roleId"),
      },
    };
  },
  methods: {
    // 富文本图片上传成功
    uploadSuccess(res) {
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == 0) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片res.url为服务器返回的图片地址
        quill.insertEmbed(
            length,
            "image",
            window.ntkoHost + "/" + res.data.url
        );
        this.$emit("allUrls", res.data.allUrl);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },
    // //上传视频
    // uploadSuccessVideo(res) {
    //   let quill = this.$refs.myQuillEditor.quill;
    //   // 如果上传成功
    //   if (res.code == 0) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     // 插入图片res.url为服务器返回的地址
    //     quill.insertEmbed(length, "video", res.data.url);
    //     // 调整光标到最后
    //     quill.setSelection(length + 1);
    //   } else {
    //     this.$message.error("视频插入失败");
    //   }
    // },
    // //上传音频-处理很重要！！！！
    // uploadSuccessVoice(res) {
    //   let quill = this.$refs.myQuillEditor.quill;
    //   // 如果上传成功
    //   if (res.code == 0) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     let BlockEmbed = Quill.import("blots/block/embed");

    //     class AudioBlot extends BlockEmbed {
    //       static create(value) {
    //         let node = super.create();
    //         node.setAttribute("src", res.data.url); //设置audio的src属性
    //         node.setAttribute("controls", true); //设置audio的controls，否则他将不会显示
    //         node.setAttribute("controlsList", "nodownload"); //设置audio的下载功能为不能下载
    //         node.setAttribute("id", "voice"); //设置一个id
    //         return node;
    //       }
    //     }

    //     AudioBlot.blotName = "audio";
    //     AudioBlot.tagName = "audio"; //自定义的标签为audio
    //     Quill.register(AudioBlot);

    //     // insertEmbed(index: Number(插入的位置), type: String(标签类型), value: any(参数，将传入到create的方法中去), source: String = 'api')
    //     quill.insertEmbed(length, "audio", res.data.url);
    //     quill.setSelection(length + 1); //光标位置向后移动一位
    //   } else {
    //     this.$message.error("音频插入失败");
    //   }
    // },
    // 富文本图片/视频/音频上传失败
    uploadError() {
      this.$message.error("插入失败");
    },
    // // 失去焦点事件
    // onEditorBlur(e) {
    //   console.log("onEditorBlur: ", e);
    // },
    // // 获得焦点事件
    // onEditorFocus (e) {
    //   console.log("onEditorFocus: ", e);
    // },
    // // 内容改变事件
    // onEditorChange(e) {
    //   console.log("onEditorChange: ", e);
    // },
    onEditorReady(e) {
      if (this.disabled) {
        document.getElementsByClassName("ql-toolbar")[0].style.display = "none"
        document.getElementsByClassName("ql-container")[0].style.borderTop = "1px solid #ccc"
        e.enable(false); // 查看时只读
      }
    },
    initTitle() {
      document.getElementsByClassName("ql-editor")[0].dataset.placeholder = "";
      for (let item of titleConfig) {
        let tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    },
    editorChange(val) {
      this.$emit("update:value",val)
    }
  },
  mounted() {
    // 各个工具添加悬浮提示
    this.initTitle();
  },
};
</script>

<style>
.avatar-uploader-editor {
  display: inline-block;
  display: none;
}

.avatar-uploader-editor-video {
  display: inline-block;
}

.avatar-uploader-editor-voice {
  display: inline-block;
}
.editor {
  line-height: normal !important;
  height: 500px;
}


.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  sqlStatement: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  sqlStatement: "10px";
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  sqlStatement: "12px";
  font-size: 12px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  sqlStatement: "16px";
  font-size: 16px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  sqlStatement: "18px";
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  sqlStatement: "20px";
  font-size: 20px;
}
</style>
