<template>
  <div id="app">
    <router-link to="/"></router-link>
    <MenuOptionTop v-if="!$route.meta.notShowNavMenu"></MenuOptionTop>
<!--    <router-view></router-view>-->
    <keep-alive exclude="dishes">
      <router-view :key="this.$route.path"></router-view>
    </keep-alive>


    <div id="beian">
<!--      <div style="width:300px;margin:0 auto; padding:20px 0;">-->
<!--        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051684" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010502051684号</p></a>-->
<!--        -->
<!--      </div>-->
      <div style="display:inline-block;line-height: 30px;">
        <img src="../public/gaLogo.png" style="vertical-align: sub">
      </div>
      <div style="display:inline-block">
        <a class="beianA" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051684" target="value"> 京公网安备 11010502051684号</a>
      </div>
      <div class="beianA" style="display:inline-block">
        &ensp;|&ensp;
      </div>
      <div style="display:inline-block">
        <a class="beianA" href="https://beian.miit.gov.cn" target="value">冀ICP备2023002291号-1</a>
      </div>
    </div>
  </div>
</template>

<script>

import MenuOptionTop from "@/components/menu/MenuOptionTop";

export default {
  name: 'App',
  components: {
    MenuOptionTop
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#beian {
  position: sticky;

  height: 25px;
  background-color: #606266;
  font-size: 15px;
  margin-top: 2.5%;
  top: 96.5%;
}
.beianA {
  color: #ffffff;
  text-decoration: none;
  text-align: center;
}
</style>
