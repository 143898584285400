"use strict";

import Vue from 'vue';
import axios from "axios";
import router from '../router/index'

let globalURL= window.apiIp;
let config = {
    baseURL: process.env.apiUrl || globalURL,
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};
Vue.golalURL=globalURL;

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        // (sessionStorage.getItem('token')) && (config.headers['Sin-Token'] = sessionStorage.getItem('token'));
        // (localStorage.getItem('roleId')) && (config.headers['Sin-RoleId'] = localStorage.getItem('roleId'))
        // config.headers['Origin'] = "http://localhost:8080"

        if (localStorage.getItem("token")) {
            config.headers.token = localStorage.getItem("token");
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    function (response) {
        if (response.data.code === 2001 || response.data.code === 2100) {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('isLogin', 0);//未登录
            router.push({ name:"loginPage" });
        } else {
            localStorage.setItem('isLogin', 1);//已登录
            return response.data;
        }
    },
    function (error) {
        // if (error.response && error.response.status && error.response.status == 401) {
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     localStorage.setItem('isLogin', 0); //未登录
        //     vm.$router.push({
        //         path: '/indexzj',
        //         query: {
        //             redirect: vm.$route.path,
        //             hostname: location.hostname
        //         }
        //     })
        // }
        return Promise.reject(error);
    }
);
Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;

export { _axios }