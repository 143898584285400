import Vue from 'vue';

// 引用axios
// import axios from 'axios';
import './plugins/axios'
// 引用路由
// import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import router from "./router";
// 引用element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入vuex
import Vuex from 'vuex';
//导入全局样式表
import './assets/css/globe.css'

import App from './App.vue';

Vue.config.productionTip = false

// Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(Vuex);

new Vue({
  beforeCreate() {
    // 定义全局事件总线。
    Vue.prototype.$bus = this;
  },
  render: h => h(App),
  router: router
}).$mount('#app')
